import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { SdkWandIcon } from '../../../../assets/svgs'
import SDKConfigPreviewComp from './preview'
import BiometricsComp from './verificationChannels/biometrics'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { RootState } from '../../../../redux/reducers'
import {
    sdkEndpointsInfoRequest,
    sdkInfoAllRequest,
    sdkInfoSingleRequest,
    sdkUpdateRequest,
} from '../../../../redux/actions/sdkLibraries'
import Mainloader from '../../../utils'
import { Spinner } from 'react-bootstrap'
import NotificationToast from '../../../utils/notifToast'
import DynamicCheckPreview from '../../../../assets/Dynamism_check.png'
import RadioBtnWithLabel from '../../../form/RadioWithLabel'
import micIcon from '../../../../assets/svgs/microphone.svg'
import imgIcon from '../../../../assets/svgs/Image.svg'
import RangeSlider from '../../../RangeSlider/RangeSlider'
import { generateSdkUrlIDApi, switchSDKEnvApi } from '../../../../redux/sagas/apiLibraries/application'
import { generateUniqueRandomNumberString } from '../Lists'
import Button from '../../../form/button/Button'
import Modal from '../../../modal/Modal'

const colors = [
    '#000000',
    '#1633A3',
    '#F44336',
    '#E91E62',
    '#9C27B0',
    '#673AB6',
    '#3F50B5',
    '#2096F3',
    '#00A8F4',
    '#00BCD4',
    '#009688',
    '#4CAF4F',
    '#8BC24A',
    '#CDDC39',
    '#FFC007',
    '#FF9800',
    '#FF5721',
    '#795548',
]
const tempoaryGestures = [
    {"gesture":"Closed_Fist","instruction":"Make a closed fist sign with your right hand ✊"},
    {"gesture":"Open_Palm","instruction":"Open your right palm widely and face it towards the camera 👋"},
    {"gesture":"Pointing_Up","instruction":"Point upwards with your index finger ☝️"},
    {"gesture":"Thumb_Down","instruction":"Give a thumbs down with your hand 👎"},
    {"gesture":"Thumb_Up","instruction":"Give a thumbs up with your hand 👍"},{"gesture":"Victory","instruction":"Position your palm facing outwards with your index and middle fingers pointed upwards, forming a 'V' shape ✌️"},{"gesture":"I_Love_You","instruction":"Extend your thumb, index finger, and pinky to display the 🤟 hand gesture "}
]
const HandleSuccessOnConfigModal = ({mode,onSuccess}:{mode:'LIVE'|'SANDBOX',onSuccess:any})=>{
    const { ref:config_id } = useParams()

    const [isLoading,setIsLoading] = useState(false);
    const [isLoadingGen,setIsLoadingGen] = useState(false);
    const switchWidget =  async ()=>{
        
        setIsLoading(true)
        try {
            // @ts-ignore
            const resp = await switchSDKEnvApi({config_id,mode:'LIVE'})
            setIsLoading(false)
           
            if(onSuccess){
                onSuccess(resp)
            }
            // console.log({resp})
        } catch (error) {
            setIsLoading(false)
        }

    }
    const generateSDK = async ()=>{
        setIsLoadingGen(true)
        try{
            const resp = await generateSdkUrlIDApi({
                    "email":`dummy${mode}@prembly.com`,
                    "first_name":'prembly',
                    "last_name" :'test',
                    "user_ref":generateUniqueRandomNumberString(),
                    "config_id":config_id,
                    "mode": mode
            })
            setIsLoadingGen(false)
            // copy
            if(window.location.hostname.includes('dev')){
                window.open(`https://d134s5jj8i8a8y.cloudfront.net/index.html?ref=${resp.url_widget_id}`,'_blank')
            }else{
                window.open(`https://widget-preview.prembly.com/index.html?ref=${resp.url_widget_id}`,'_blank')
            }

        }catch(err:any){
            setIsLoadingGen(false)
        }
    }


    return (
        <div>
            <h1 style={{'textAlign':'center','fontFamily':'--general-sans-regular',
            fontWeight:'500',fontSize:'1.2rem'
        }}>Success!</h1>
            <p
            style={{textAlign:'center',fontFamily:'--general-sans-regular',fontWeight:'500',fontSize:'1rem'}}
            >
            Your Verification has been saved! You can view our documentation widget <strong style={{fontWeight:'600',fontFamily:'--general-sans-semibold',}}><a style={{color:'black'}} href="https://docs.prembly.com/docs/prembly-sdk" target='_blank'>here</a></strong>
            </p>

            {/* {
                mode==='LIVE'?
                <Button
            style={{width:'100%',borderRadius:'1rem','padding':'1rem 0',
            fontFamily:'--general-sans-medium',fontWeight:'700'
        }}
                >
                Switch to Live
            </Button>:''
            } */}

            <div style={{'display':'flex','alignItems':'center','gap':'1.5rem',flexDirection:'column'}}>
            <Button
            style={{width:'100%',borderRadius:'1rem','padding':'1rem 0',
            fontFamily:'--general-sans-medium',fontWeight:'700'
        }}
        onClick={()=>{
            switchWidget()
        }}
                >
                    {
                        isLoading?
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />:
                    ' Switch to Live'
                    }
               
            </Button>
            <Button
            style={{width:'100%',borderRadius:'1rem','padding':'1rem 0',backgroundColor:'#F4F4F4',color:'#7D7D7D',
        fontFamily:'--general-sans-medium',fontWeight:'700',
        }}
        onClick={()=>{
            generateSDK()
        }}
            >
               
               {
                        isLoadingGen?
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />:
                    'Test Your Widget'
                    }
            </Button>
            </div>
        </div>
    )
}
export default function SDKConfigComp() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [themeColor, setThemeColor] = useState('')
    const [configPage, setConfigPage] = useState(1)
    const [sdkChannelPage, setSdkChannelPage] = useState<any>('')
    const [sdkChannelOther, setSdkChannelOther] = useState<any>([])

    const [widgetName, setWidgetName] = useState('')
    const [description, setDescription] = useState('')
    const [selectedCountry, setSelectedCountry] = useState<any>({ code: '', country: '' })
    const [selectedEndpoints, setSelectedEndpoints] = useState<any>([])
    const [isActive, setIsActive] = useState<number>()
    const [selectedBiometrics, setSelectedBiometrics] = useState([])
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState('')
    const [notifTitle, setNotifTitle] = useState('')
    const [saveError,setSaveError]= useState<any>(null)
    const [faceConfidence,setFaceConfidence] = useState(85)
    const [showDynamism,setShowDynamism] = useState(true);
    const [arrayOfSelectedCountries,setArrayOfSelectedCountries] = useState([])
    const [openSuccess,setOpenSuccess] = useState(false);

    // const [createdProduct, set] = useState('')

    // const [theme, setTheme] = useState('')

    const singleSdk = useSelector((state: RootState) => state.sdkInfoSingleReducer)
    const endpointLists = useSelector((state: RootState) => state.sdkEndpointsInfoReducer)
    const updateSdk = useSelector((state: RootState) => state.sdkUpdateReducer)


    const { ref } = useParams()


    useEffect(() => {
        getSingleSdk()
    }, [])

    useEffect(() => {
        getProductsEndpoints()
    }, [])

    useEffect(() => {
        if (singleSdk) {
            const selectedProduct =
                singleSdk?.resp?.data?.product === 'IDENTITYPASS'
                    ? 'idpass'
                    : singleSdk?.resp?.data?.product === 'IDENTITYRADAR'
                    ? 'radar'
                    : singleSdk?.resp?.data?.product === 'BACKGROUNDCHECK'
                    ? 'bgc'
                    : 'dynamism_checks'
            setWidgetName(singleSdk?.resp?.data?.name)
            setDescription(singleSdk?.resp?.data?.subtitle)
            setThemeColor(singleSdk?.resp?.data?.theme_color)
            setIsActive(singleSdk?.resp?.data?.is_active ? 1 : 0)
            setSdkChannelPage(selectedProduct)
            setSdkChannelOther([selectedProduct, 'dynamism_checks'])

            if(singleSdk?.resp?.data?.dynamism){
                setSelectedBiometrics( singleSdk.resp.data.dynamism)

            }
        }
    }, [ref, singleSdk])

    // console.log(sdkChannelPage, 'channel', sdkChannelOther)

    useEffect(() => {
        getAvailableChecker()
    }, [])

    useEffect(()=>{
        
        if(endpointLists){
            if(sdkChannelPage=='idpass'|| sdkChannelPage==='radar'){
                let endpointsArray = [
                    ...selectedEndpoints,
                    ...singleSdk?.resp.data?.endpoints.map((d:any)=>({
                        "endpoint_id": d.endpoint.id,
                        "country_code": d.endpoint.country_code,
                        "country": d.endpoint.country,
                        "is_active": true,
                        "endpoint_name": d.endpoint.name,
                        "endpoint_code":d.endpoint.code,
                        "is_document": false,
                        "is_individual": true,
                        "is_business": false
                    }))//--this is coming from previously saved in the db had to rewrite cus the backend is send different structure
                ];
                let removeDuplicateEndPointArray = Object.values(
                    endpointsArray.reduce((acc, obj) => {
                        acc[obj.endpoint_id] = obj;
                        return acc;
                    }, {})
                );

                setSelectedEndpoints(removeDuplicateEndPointArray)
                // console.log({'endpointLists':endpointLists?.resp?.data[sdkChannelPage],sdkChannelPage})
            }
            // endpointLists?.resp?.data[sdkChannelPage]
        }
    },[endpointLists,sdkChannelPage])
    let getSingleSdk = () => {
        const callback = (data: any) => {
            // if (data.status) {
            //     if (data?.detail?.request_completed) {
            //         setFormPage(3)
            //     }
            // }
            // else {
            //     setNotifTitle("Error")
            //     setNotif(data.detail)
            //     setNotifVal(true)
            // }
        }
        let data: any = {
            values: {
                config_id: ref,
            },
            callback,
        }
        dispatch(sdkInfoSingleRequest(data))
    }

    let getProductsEndpoints = () => {
        const callback = (data: any) => {

            // singleSdk?.resp.data?.endpoints?.filter((obj:any)=>obj.endpoint.name===endpoint.endpoint_name).length!==0
            // if (data.status) {
            //     if (data?.detail?.request_completed) {
            //         setFormPage(3)
            //     }
            // }
            // else {
            //     setNotifTitle("Error")
            //     setNotif(data.detail)
            //     setNotifVal(true)
            // }
        }
        let data: any = {
            callback,
        }
        dispatch(sdkEndpointsInfoRequest(data))
    }

    let getAvailableChecker = () => {
        const callback = (data: any) => {
            if (!data.status) {
                setNotifTitle('Error')
                setNotif(data.detail)
                setNotifVal(true)
            }
        }
        let data: any = {
            values: {},
            callback,
        }
        dispatch(sdkInfoAllRequest(data))
    }

    let sdkUpdate = () => {
        // const selectedDynamism = selectedBiometrics.map((obj: any) => ({ check: obj.value }))
        const selectedDynamism = selectedBiometrics

        if(showDynamism){
            if(selectedDynamism.length<3){
                setSaveError('Dynamism must be 3 or more')
                return 
            }
        }

        const endpointsSelected = selectedEndpoints.map((endpoint: any) => ({
            endpoint: endpoint.endpoint_id,
        }))
        // const selectedCountries = selectedCountry

        const payload = {
            config_id: ref,
            name: widgetName,
            subtitle: description||'description',
            countries: [
                // { code: selectedCountry.code, name: selectedCountry.country },
                ...arrayOfSelectedCountries
                // selectedCountry,
                // {
                //     code: 'NG',
                //     name: 'Nigeria',
                // },
            ],
            // endpoints: [
            //     { endpoint: 'fd00836d-c854-43d5-99ee-b580da7f349f' },
            //     { endpoint: 'fadv00836d-c854-43d5-99ee-b580da7f349g' },
            // ],
            endpoints: endpointsSelected,
            // radar_endpoints: {},
            // radar_endpoints: [{ endpoint: 'AML' }, { endpoint: 'SANCTIONS' }],
            // dynamism: [{ check: 'I_Love_You' }, { check: 'Smile' }],
            dynamism: selectedDynamism,
            face_confidence: faceConfidence,
            is_active: isActive,
            theme_color: themeColor,
        }

        const callback = (data: any) => {
            if (data.status) {
                setNotifTitle('Success')
                setNotif(data.detail)
                setNotifVal(true)
                // navigate('/SDK-Library/Config?notify=updated')
                setOpenSuccess(true)
            } else {
                setNotifTitle('Error')
                setNotif(data.detail)
                setNotifVal(true)
            }
        }
        let data: any = {
            values: payload,
            callback,
        }
        dispatch(sdkUpdateRequest(data))
    }

    const handleChange = (e: any) => {
        const { value, name } = e.target

        if (name === 'widgetName') {
            setWidgetName(value)
        } else if (name === 'description') {
            setDescription(value)
        }
        // setWidgetName(e.target.value)
    }

    const uniqueCountries =
        endpointLists &&
        endpointLists?.resp?.data?.[sdkChannelPage]?.reduce((acc: any, obj: any) => {
            const country = obj.country
            if (!acc.find((item: any) => item.country === country)) {
                // Check for duplicate countries
                acc.push({ country, code: obj.country_code })
            }
            return acc
        }, [])

    const handleSelectCountry = (e: any) => {
        setSelectedCountry(JSON.parse(e.target.value))
        setSelectedEndpoints([])
    }

    // console.log(countries, 'pop', uniqueCountries)
    // console.log(selectedCountry && selectedCountry.code, '0o')

    const payload = {
        config_id: 'aa1827d2-c44e-416b-825a-fdeadb456961',
        name: 'Edcoelab SDK Dev',
        subtitle: 'Just verification',
        countries: [
            selectedCountry,
            // {
            //     code: 'NG',
            //     name: 'Nigeria',
            // },
        ],
        endpoints: [
            { endpoint: 'fd00836d-c854-43d5-99ee-b580da7f349f' },
            { endpoint: 'fadv00836d-c854-43d5-99ee-b580da7f349g' },
        ],
        radar_endpoints: [{ endpoint: 'AML' }, { endpoint: 'SANCTIONS' }],
        dynamism: [{ check: 'I_Love_You' }, { check: 'Smile' }],
        face_confidence: '9800',
        is_active: 1,
        theme_color: '#F44336',
    }

    const handleSelectedEndpoints = (e: any, endpoints: any) => {
        console.log({newAddEnd:endpoints})
        // setSelectedEndpoints([{ ...selectedEndpoints, ...endpoints }])
        if (e.target.checked) {
            setSelectedEndpoints((prev: any) => [...prev, endpoints])
        } else {
            const newArr = selectedEndpoints

            .filter(
                (endpoint: any) => endpoint.endpoint_code !== endpoints.endpoint_code
            )
            setSelectedEndpoints(newArr)
        }
    }

    const handleSelectBiometric = (selected: any) => {
        setSelectedBiometrics(selected)
    }

    const handleMultiSelectCountry =(selected:any)=>{
        let availableCountrys = selected?.map((d:any)=>d.label)

        // const modifyEndpoint =[...selectedEndpoints.filter((endpoint:any)=>{
        //     if(availableCountrys.includes(endpoint.country) === false) {
        //         // so this means the coutry has been deslsected
        //         // return {...endpoint,is_active:false}
        //         return  false
        //     }

        //     // return endpoint;
        //     return true;
        // })]
        // setSelectedEndpoints(modifyEndpoint)                                                    
        //    )}

        // @ts-ignore
        setArrayOfSelectedCountries([...selected.map(d=>{
            return JSON.parse(d.value)
        })])
    }

    const handleRadioWithLabel = ({value,isChecked}:{value:string,isChecked?:boolean})=>{
        if(isChecked){
            // @ts-ignore
            setSelectedBiometrics([...selectedBiometrics,{check:value}])
        }else{
            // @ts-ignore
            setSelectedBiometrics([...selectedBiometrics.filter(d=>d.check!==value)])
        }
    }
    const handleSaveChange = () => {
        const selectedDynamism = selectedBiometrics.map((obj: any) => ({ check: obj.value }))
        const endpointsSelected = selectedEndpoints.map((endpoint: any) => ({
            endpoint: endpoint.endpoint_code,
        }))
        // const selectedCountries = selectedCountry

        const payload = {
            config_id: ref,
            name: widgetName,
            subtitle: description,
            countries: [
                { code: selectedCountry.code, name: selectedCountry.country },
                // selectedCountry,
                // {
                //     code: 'NG',
                //     name: 'Nigeria',
                // },
            ],
            // endpoints: [
            //     { endpoint: 'fd00836d-c854-43d5-99ee-b580da7f349f' },
            //     { endpoint: 'fadv00836d-c854-43d5-99ee-b580da7f349g' },
            // ],
            endpoints: endpointsSelected,
            // radar_endpoints: [{ endpoint: 'AML' }, { endpoint: 'SANCTIONS' }],
            // dynamism: [{ check: 'I_Love_You' }, { check: 'Smile' }],
            dynamism: selectedDynamism,
            face_confidence: '9800',
            is_active: isActive,
            theme_color: themeColor,
        }

        try {
            // const res
        } catch (error) {}

    }

    // const handleSelectedRadarEndpoints = (e: any, endpoint: any) => {
    //     console.log(endpoint, 'wem')
    // }
    const handleSelectedRadarEndpoints = (e: any, endpoints: any) => {
        // setSelectedEndpoints([{ ...selectedEndpoints, ...endpoints }])
        // console.log(e.target.checked, 'end')
        if (e.target.checked) {
            setSelectedEndpoints((prev: any) => [...prev, endpoints])
        } else {
            const newArr = selectedEndpoints.filter(
                (endpoint: any) => endpoint.endpoint_code !== endpoints.endpoint_code
            )
            setSelectedEndpoints(newArr)
        }
    }


    useEffect(()=>{
        if(showDynamism){
            setSelectedBiometrics([])
        }
    },[showDynamism])

    console.log({selectedEndpoints})
    return (
        <>


            {ref && singleSdk.isLoading ? (
                <Mainloader />
            ) : (
                <div>
{/* {JSON.stringify(singleSdk)} */}

                <Modal 
                    open={openSuccess}
                    setOpen={setOpenSuccess}
                    modalTitle=""
                    modalBoxstyle={{'height':'unset',width:'384px'}}
                    >
                        <div>
                            {
                                singleSdk.resp?.data?.widget_mode?
                                <HandleSuccessOnConfigModal
                                mode={singleSdk.resp?.data?.widget_mode}
                                onSuccess={(resp:any)=>{
                                    setNotifVal(true)
                                    setNotifTitle('Config Enviroment Switch')
                                    setNotif(resp.detail)
                                            
                                    getAvailableChecker()


                                    setTimeout(()=>{
                                        navigate('/SDK-Library/Config')
                                    },1000)
                                    setOpenSuccess(false)


                                }}
                                
                                />:''
                            }

                        </div>
                    </Modal>


                    {notif && notifVal && (
                        <NotificationToast
                            title={notifTitle}
                            message={notif}
                            closeNotif={() => setNotifVal(!notifVal)}
                        />
                    )}
                    <div className="row">
                        <div 
                        // className={arrayOfSelectedCountries?.length===0?"col-md-12":"col-md-6"}
                        className={"col-md-6"}
                        >
                            <div className="card">
                                {configPage === 1 && (
                                    <div className="card-body">
                                        <h5>Layout Configuration</h5>
                                        <div
                                            className="rounded-3 p-3"
                                            style={{ background: '#FFF2E6' }}>
                                            <span>{SdkWandIcon}</span>
                                            <p>
                                                Configure your widget element here by selecting the
                                                endpoints you want for each product.
                                            </p>
                                        </div>
                                        <div>
                                            <div className="">
                                                <label htmlFor="name">Widget/Brand Name </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter widget name"
                                                    value={widgetName}
                                                    name="widgetName"
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="">
                                                <label htmlFor="desc">Description </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="For Verification"
                                                    value={description}
                                                    name="description"
                                                    onChange={handleChange}
                                                    // onChange={pass => setNewPassword(pass.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="border p-3 rounded-3 mt-3">
                                            <h6>Select Theme</h6>

                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span
                                                        className="input-group-text bg-white"
                                                        style={{ borderRadius: '5px 0px 0px 5px' }}>
                                                        <input
                                                            type="color"
                                                            name="theme"
                                                            style={{ height: '40px' }}
                                                            className="border-0"
                                                            placeholder="#ffffff"
                                                            value={themeColor}
                                                            // value={themeColor}
                                                            // value={themeColor || currentState.theme_color}
                                                            // defaultValue={currentState.theme_color}
                                                            onChange={(e) => {
                                                                // setThemeColorError('')
                                                                setThemeColor(e.target.value)
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="theme"
                                                    className="form-control"
                                                    // placeholder={currentState.theme_color || '#ffffff'}
                                                    value={themeColor}
                                                    // defaultValue={currentState.theme_color}
                                                    onChange={(e) => {
                                                        // setThemeColorError('')
                                                        setThemeColor(e.target.value)
                                                    }}
                                                />
                                            </div>
                                            {/* {themeColorError ? (
                                    <small className={styles.inputError}>{themeColorError}</small>
                                ) : null} */}
                                            <div className="d-flex flex-wrap">
                                                {colors.map((color, index) => (
                                                    <div
                                                        key={index}
                                                        className="rounded-circle me-2 mt-2"
                                                        style={{
                                                            backgroundColor: color,
                                                            width: '30px',
                                                            height: '30px',
                                                        }}
                                                        onClick={(e) => {
                                                            // setThemeColorError('')
                                                            // console.log({e:e.target.value})
                                                            setThemeColor(color)
                                                        }}></div>
                                                ))}
                                            </div>
                                        </div>
                                        <button
                                            className="btn btn-green w-100 mt-3 py-3"
                                            onClick={() => setConfigPage(2)}>
                                            Next
                                        </button>
                                    </div>
                                )}
                                {configPage === 2 && (
                                    <div className="card-body">
                                        <button
                                            className="btn bg-light mb-3"
                                            onClick={() => setConfigPage(1)}>
                                            <i className="ri-arrow-left-line me-3" />
                                            Back To Layout
                                        </button>
                                        <h5>Verification Channels Configuration</h5>
                                        <div>
                                            <div className="d-flex mt-3 sdk-channel-area">
                                                {[
                                                    {
                                                        key: 'idpass',
                                                        name: 'Identity Pass',
                                                        isDisabled: true,
                                                    },
                                                    {
                                                        key: 'radar',
                                                        name: 'ID Radar',
                                                        isDisabled: true,
                                                    },
                                                    // {
                                                    //     key: 'bgc',
                                                    //     name: 'Background Check',
                                                    //     isDisable: true,
                                                    // },
                                                    {
                                                        key: 'dynamism_checks',
                                                        name: 'Biometrics',
                                                        isDisable: false,
                                                    },
                                                ]?.map((val: any) => (
                                                    <h6
                                                        className={`me-3 sdk-channel-page ${
                                                            sdkChannelPage === val.key && 'active'
                                                        } `}
                                                        onClick={() => setSdkChannelPage(val.key)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            // pointerEvents:
                                                            //     sdkChannelPage !== val.key ||
                                                            //     !val.isDisabled
                                                            //         ? 'none'
                                                            //         : 'unset',
                                                            display: sdkChannelOther.includes(
                                                                val.key
                                                            )
                                                                ? 'block'
                                                                : 'none',
                                                        }}>
                                                        {val.name}
                                                    </h6>
                                                ))}
                                            </div>
                                            {sdkChannelPage === 'idpass' && (
                                                <div className="">
                                                    <label htmlFor="name">Select Country </label>
                                                    <Select
                                                isMulti
                                                onChange={handleMultiSelectCountry}
                                                options={uniqueCountries?.sort((a:any,b:any)=>a.country.toLowerCase().localeCompare(b.country.toLowerCase())).map((country: any) => ({
                                                    label: country.country,
                                                    value: JSON.stringify(country),
                                                }))}
                                            /> 
                                                    
                                                    {/* <select
                                                        name=""
                                                        id=""
                                                        // value={selectedCountry.country}
                                                        className="form-select"
                                                        onChange={handleSelectCountry}
                                                    >
                                                        <option value="">
                                                            {selectedCountry?.country
                                                                ? selectedCountry.country
                                                                : 'Select Country'}
                                                        </option>
                                                        {uniqueCountries &&
                                                            uniqueCountries.map((country: any) => (
                                                                <option
                                                                    value={JSON.stringify(country)}>
                                                                    {country.country}
                                                                </option>
                                                            ))}
                                                    </select> */}
                                                    <small>
                                                        The country you select will determine the
                                                        endpoints you see below
                                                    </small>
                                                </div>
                                            )}
                                        </div>

                                        {sdkChannelPage === 'idpass' && (
                                            <div className="mt-3">
                                                {selectedCountry.code && (
                                                    <h5>
                                                        Enable the verification endpoint you want
                                                    </h5>
                                                )}

                                                {/* <p>{JSON.stringify(endpointLists?.resp?.data[sdkChannelPage])}</p> */}
                                                <div>   


                                                    {endpointLists?.resp?.data?.[sdkChannelPage]
                                                        ?.filter(
                                                            // @ts-ignore
                                                            (endpoint: any) =>arrayOfSelectedCountries.map(d=>d.code).includes(endpoint?.country_code)
                                                                // endpoint?.country_code ===
                                                                // selectedCountry.code
                                                            // singleSdk?.resp.data?.endpoints?.filter((obj:any)=>obj.endpoint.name===endpoint.endpoint_name).length!=0

                                                        )
                                                        .map((endpoint: any) => (
                                                            <div className="d-flex justify-content-between">
                                                                <p className="pt-3">
                                                                ({endpoint.country_code}) {endpoint.endpoint_name} 
                                                                </p>
                                                                <label className="switch ms-3">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            selectedEndpoints.some(
                                                                            (obj: any) =>
                                                                                obj.endpoint_id ===
                                                                                endpoint.endpoint_id
                                                                        )
                                                                        // selectedEndpoints.filter((d:any)=>d.endpoint_code==endpoint.endpoint_code).length!==0
                                                                    }
                                                                        onChange={(e) =>
                                                                            handleSelectedEndpoints(
                                                                                e,
                                                                                endpoint
                                                                            )
                                                                        }
                                                                    />
                                                                    {/* {JSON.stringify()} */}
                                                                    <span className="slider" />
                                                                </label>
                                                                
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        )}
                                        {sdkChannelPage === 'radar' && (
                                            <div className="mt-3">

<div className="">
                                                    <label htmlFor="name">Select Country </label>
                                                    <Select
                                                isMulti
                                                onChange={handleMultiSelectCountry}
                                                options={uniqueCountries.map((country: any) => ({
                                                    label: country.country,
                                                    value: JSON.stringify(country),
                                                }))}
                                            /> 
                                                    
                                                    {/* <select
                                                        name=""
                                                        id=""
                                                        // value={selectedCountry.country}
                                                        className="form-select"
                                                        onChange={handleSelectCountry}
                                                    >
                                                        <option value="">
                                                            {selectedCountry?.country
                                                                ? selectedCountry.country
                                                                : 'Select Country'}
                                                        </option>
                                                        {uniqueCountries &&
                                                            uniqueCountries.map((country: any) => (
                                                                <option
                                                                    value={JSON.stringify(country)}>
                                                                    {country.country}
                                                                </option>
                                                            ))}
                                                    </select> */}
                                                    <small>
                                                        The country you select will determine the
                                                        endpoints you see below
                                                    </small>
                                                </div>
                                                {/* <h5>Enable the verification endpoint you wants</h5> */}

                                                <div>
                                                    {endpointLists?.resp?.data?.[
                                                        sdkChannelPage
                                                    ]
                                                    ?.filter(
                                                        // @ts-ignore
                                                        (endpoint: any) =>arrayOfSelectedCountries.map(d=>d.code).includes(endpoint?.country_code)
                                                            // endpoint?.country_code ===
                                                            // selectedCountry.code
                                                    )
                                                    .map((endpoint: any) => (
                                                        <div className="d-flex justify-content-between">
                                                            <p className="pt-3">
                                                                {endpoint.endpoint_name?.replace(
                                                                    /_/g,
                                                                    ' '
                                                                )}
                                                            </p>
                                                            <label className="switch ms-3">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={
                                                                        selectedEndpoints.some(
                                                                            (obj: any) =>
                                                                                obj.endpoint_code ===
                                                                                endpoint.endpoint_code
                                                                        )
                                                            //         ||
                                                            // singleSdk?.resp.data?.endpoints?.filter((obj:any)=>obj.endpoint.name===endpoint.endpoint_name).length!=0
                                                                }
                                                                    onChange={(e) =>
                                                                        handleSelectedRadarEndpoints(
                                                                            e,
                                                                            endpoint
                                                                        )
                                                                    }
                                                                />
                                                                <span className="slider" />
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        {sdkChannelPage === 'dynamism_checks' && (
                                         <div>
                                            {/* <p>Prevent fraudulent activity with dynamic liveness checks! These on-demand challenges ensure your users are real people, not pre-recorded videos trying to bypass verification.</p>
                                            <p>Here's how it works: During the verification process, users will be presented with a random selection of prompts (you choose at least 3) that they must complete. This simple yet effective measure throws off preprogrammed attacks and strengthens your app's security.</p> */}
                                               {/* <Select
                                                isMulti
                                                onChange={handleSelectBiometric}
                                                options={endpointLists?.resp?.data?.[
                                                    sdkChannelPage
                                                ].map((endpoint: any) => ({
                                                    label: endpoint.value,
                                                    value: endpoint.key,
                                                }))}
                                            /> */}
                                               <div
                                            className="rounded-3 p-3"
                                            style={{ background: '#FFF2E6' }}>
                                            <span>{SdkWandIcon}</span>
                                            <p>
                                                Select a  minimum of 3 prompts to be randomly displayed to your users during verification
                                            {/* Please note that this is currently experimental in nature and may fail. Selections made below will be randomised to your users when trying to do their facial verifications */}
                                            </p>
                                        </div>
                                        <br />
 

                                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>

                                            <h1 
                                        style={{'fontFamily':'--general-sans-medium',fontWeight:'500','fontSize':'18px',
                                        display:'flex','alignItems':'center',
                                    }}
                                        // className='flex'
                                        >
                                                <span>Face Dynamism</span>
                                            <OverlayTrigger 
                                                                    // placement="top"
																		overlay={
																			<Tooltip>
                                                                                Face dynamism adds an extra layer of security to your app, by randomly displaying prompts for your user to carry out during
                                                                                verification. Please  not that this is an experimental feature so results may vary
																			{/* Select a minimum of 3 prompt to be randomly displayed to your users during verification */}
																			</Tooltip>
																		}
																	>
																	<div className='cursor-pointer'>
                                                                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M21.4167 21.4046C21.4167 21.0825 21.6778 20.8213 22 20.8213C22.3222 20.8213 22.5833 21.0825 22.5833 21.4046V24.9046C22.5833 25.2268 22.3222 25.488 22 25.488C21.6778 25.488 21.4167 25.2268 21.4167 24.9046V21.4046Z" fill="#22221E"/>
                                                                        <path d="M22 18.5298C21.6778 18.5298 21.4167 18.791 21.4167 19.1132C21.4167 19.4353 21.6778 19.6965 22 19.6965C22.3222 19.6965 22.5833 19.4353 22.5833 19.1132C22.5833 18.791 22.3222 18.5298 22 18.5298Z" fill="#22221E"/>
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 16.1667C18.7783 16.1667 16.1667 18.7783 16.1667 22C16.1667 25.2217 18.7783 27.8333 22 27.8333C25.2217 27.8333 27.8333 25.2217 27.8333 22C27.8333 18.7783 25.2217 16.1667 22 16.1667ZM17.3333 22C17.3333 24.5773 19.4227 26.6667 22 26.6667C24.5773 26.6667 26.6667 24.5773 26.6667 22C26.6667 19.4227 24.5773 17.3333 22 17.3333C19.4227 17.3333 17.3333 19.4227 17.3333 22Z" fill="#22221E"/>
                                                                        </svg>
                                                                    </div>
																	</OverlayTrigger>
                                            </h1>
                                            <label
                                            className="switch ms-3">
    <input
    type="checkbox"
    checked={showDynamism}
    onChange={(e) =>{
        setShowDynamism(!showDynamism);
    } }
    />
    <span className="slider" />
    </label>
                                            </div>


                                            <div 
                                            // className="bg-white px-2 py-2" 
                                            // style={{ height: "50px" }}
                                            
                                            >

																</div>
                                                {/* {JSON.stringify(  endpointLists?.resp?.data?.[sdkChannelPage])} */}
                                            {
                                                showDynamism?
                                                <div>
                                                    <div>
                                                        {
                                                             singleSdk.resp.data?
                                                             <div>
                                                                {
                                                                    // /prembly-backend-dev/checker/v1/endpoint/get
                                                        // endpointLists?.resp?.data?.[sdkChannelPage]
                                                        tempoaryGestures.map((endpoint:any,index:number)=>
                                                        (
                                                          <RadioBtnWithLabel
                                                          id={index}
                                                          type='checkbox'
                                                          defaultChecked={
                                                            singleSdk.resp.data?.dynamism?.map((d:any)=>d.check).includes(endpoint.gesture)||false
                                                          }
                                                          label={<div>
                                                              <OverlayTrigger 
                                                          overlay={
                                                          <Tooltip>
                                                          { endpoint.instruction}
                                                          </Tooltip>
                                                          }
                                                          >
                                                          <div>
                                                          {endpoint.gesture.replaceAll('_',' ')} 
                                                          </div>
      
                                                          </OverlayTrigger>
                                                          </div>}
                                                          value={endpoint.gesture}
                                                          onChange={(data)=>{
                                                              handleRadioWithLabel(data)
                                                          }}
                                                          
                                                          />
                                                      ))
                                                  }

                                                             </div>:'Loading..'
                                                        }
                                                    </div>
                                                
                                                </div>:''
                                            }

                                            

                                            <div style={{'paddingTop':'1rem'}}>
                                                <h2 style={{'fontFamily':'--general-sans-medium',fontWeight:'500','fontSize':'18px'}}>Face Confidence Level (%)</h2>
                                                <p>Default confidence level is 85%</p>
                                                <div>
                                                    <RangeSlider
                                                    onChange={(value)=>{
                                                        setFaceConfidence(value)
                                                    }}
                                                    />
                                                    <div style={{'display':'flex','justifyContent':'space-between','paddingTop':'.5rem',
                                                fontFamily:'--general-sans-semibold','fontWeight':'600'
                                                }}>
                                                        <p>0%</p>
                                                        <p>20%</p>
                                                        <p>40%</p>
                                                        <p>60%</p>
                                                        <p>80%</p>
                                                        <p>100%</p>
                                                    </div>
                                                </div>
                                            </div>
                                         </div>
                                        )}


                                        {sdkChannelPage === 'Dynamism Checks' && <BiometricsComp />}

{
    sdkChannelPage !=='dynamism_checks'?
    <button
    className="btn btn-green w-100 mt-3 py-3"
    disabled={updateSdk.isLoading}
    onClick={()=>{
        setSdkChannelPage("dynamism_checks")
        // setS
    }}>
    Next
</button>:

<>
<p className=''style={{'color':'crimson'}}><span>{saveError}</span></p>
        <button
        className="btn btn-green w-100 mt-3 py-3"
        disabled={updateSdk.isLoading}
        onClick={sdkUpdate}>
        {updateSdk.isLoading ? (
            <div>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                <span className="sr-only">Loading...</span>
            </div>
        ) : (
            'Save Changes'
        )}
    </button>
</>
}


                                    </div>
                                )}
                            </div>
                        </div>
                        {
                            // arrayOfSelectedCountries?.length!==0?
                            true?
                            <div className="col-md-6">
                            {/* ss  {JSON.stringify(selectedEndpoints)} */}
      
                                  <SDKConfigPreviewComp 
                                  selectedCountry={arrayOfSelectedCountries}
                                  selectedEndpoints={selectedEndpoints}
                                  themeColor={themeColor}
                                  tab={sdkChannelPage}
                                  widgetName={widgetName}
                                  description={description}
                                  />
                              </div>:''
                        }

                    </div>
                </div>
            )}
        </>
    )
}
